import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import IO from 'components/io';
import { Text } from './title.css';
import { Container } from '../io-headline/io-headline.css';

const Title = ({ children, as = 'span', size }) => {
  useEffect(() => {
    const hoverMove = () => {
      const ctx = document.getElementById('canvas').getContext('2d');
      ctx.canvas.addEventListener('mousemove', function(event) {
        let mouseX = event.pageX - ctx.canvas.offsetLeft;
        let mouseY = event.pageY - ctx.canvas.offsetTop;
        status.innerHTML = mouseX + ' | ' + mouseY;
      });
    };
    /*const initHover = () => {
      //const title = document.querySelectorAll('.site-title');
      typeof window !== 'undefined' &&
        window.addEventListener('load', function() {
          hoverMove();
        });
    };*/
  });
  return (
    <IO>
      {({ isVisible }) => (
        <Container isVisible={isVisible}>
          <Text className="site-title" as={as} size={size}>
            {children}
          </Text>
        </Container>
      )}
    </IO>
  );
};

Title.propTypes = {
  children: PropTypes.string.isRequired,
  as: PropTypes.string,
  size: PropTypes.oneOf(['huge', 'large']),
};

export default Title;
