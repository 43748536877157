import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Box from 'components/box';
import Title from 'components/title';
import { graphql } from 'gatsby';

const Terms = ({ data }) => (
  <Layout>
    <Box>
      <Title as="h2" size="huge">
        Terms and Conditions
      </Title>
    </Box>

    <Box>
      <div
        dangerouslySetInnerHTML={{
          __html: data.termsJson.content.childMarkdownRemark.html,
        }}
      />
    </Box>
  </Layout>
);

Terms.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Terms;

export const query = graphql`
  query TermsQuery {
    termsJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
    }
  }
`;
