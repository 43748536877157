import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  padding: 1rem 0;
  font-family: 'Alef';
  line-height: 1.75;
  user-select: text;
  p {
    color: #404040;
  }
  ${MEDIA.MIN_TABLET`
     max-width: 70vw;
  `};
`;

export const InfoBox = styled.div`
  display: none;
  ${MEDIA.MIN_TABLET`
    display:block;
    position: fixed;
    bottom: 4rem;
    left: 2rem;
    z-index: 2;
    font-size: 0.75rem;
    line-height: 1.3;
    width: 300px;  
  `};
`;
